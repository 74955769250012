import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Analytics            from "Utils/Analytics";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Button               from "Components/Utils/Button";
import Html                 from "Components/Utils/Html";
import Icon                 from "Components/Utils/Icon";
import Dialog               from "Components/Utils/Dialog";



// Styles
const Container = Styled(Dialog)`
    position: absolute;
    right: calc((100vw - 800px) / 2);
    bottom: calc(24px + 56px);
    width: 520px;
    max-height: calc(100dvh - 24px - 24px - 56px);

    @media (max-width: 800px) {
        right: var(--main-padding);
    }
`;

const Content = Styled.div`
    overflow: auto;
`;

const Product = Styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 8px 0;
    border-bottom: 1px solid rgb(235, 236, 240);

    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        border-bottom: 0;
    }
`;

const Image = Styled.img`
    width: 48px;
    height: 48px;
    object-fit: contain;

    @media (max-width: 500px) {
        display: none;
    }
`;

const Name = Styled.div`
    flex-grow: 2;
`;

const Total = Styled(Html)`
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
`;

const Empty = Styled.div`
    width: 100%;
    text-align: center;
    font-weight: 100;
    font-size: 24px;
    margin-bottom: 8px;
`;

const Buttons = Styled.div`
    display: flex;
    background-color: var(--store-button-bg);
    color: var(--store-button-text);
    border-radius: var(--border-radius-small);

    .icon {
        padding: 6px 10px;
        font-size: 16px;
        cursor: pointer;
    }
    div {
        padding: 6px 0;
    }
`;

const Footer = Styled.footer`
    display: flex;
    gap: 8px;

    .btn {
        flex-grow: 1;
    }
`;



/**
 * The Cart
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Cart(props) {
    const { open } = props;

    const { backUrl, cartQuantities, cartProducts } = Store.useState();

    const showCart    = Store.useShowCart();
    const setCart     = Store.useSetCart();
    const getText     = Store.useText();
    const confirmCart = Store.useConfirmCart();
    const cancelCart  = Store.useCancelCart();


    // The Current State
    const [ openTime, setOpenTime ] = React.useState(0);


    // Handles the Open Dialog
    React.useEffect(() => {
        if (open) {
            setOpenTime(Date.now());
            if (list.length) {
                Analytics.openCartWithItems(list.length, details, totalPrice);
            } else {
                Analytics.openCart();
            }
        }
    }, [ open ]);

    // Handles the Add
    const handleAdd = ({ id, quantity, name }) => {
        setCart(id, quantity + 1);
        Analytics.editProductInCart(name, quantity + 1);
    };

    // Handles the Remove
    const handleRemove = ({ id, quantity, name }) => {
        setCart(id, quantity - 1);
        if (quantity === 1) {
            Analytics.removeProductFromCart(name);
        } else {
            Analytics.editProductInCart(name, quantity - 1);
        }
    };

    // Handles the Confirm
    const handleConfirm = async () => {
        showCart(false);
        if (await confirmCart()) {
            Analytics.confirmCart(list.length, details, totalPrice);
            window.location.href = backUrl;
        }
    };

    // Handles the Cancel
    const handleCancel = async () => {
        showCart(false);
        if (await cancelCart()) {
            Analytics.cancelCart(openTime);
            window.location.href = backUrl;
        }
    };

    // Handles the Close
    const handleClose = () => {
        showCart(false);
        Analytics.closeCart(openTime);
    };


    // Lists the Products
    const [ list, details, totalPrice ] = React.useMemo(() => {
        const result     = [];
        let   totalPrice = 0;

        for (const [ productID, quantity ] of Object.entries(cartQuantities)) {
            if (quantity > 0) {
                const product = cartProducts[productID];
                result.push({
                    id       : productID,
                    name     : product.name,
                    hasImage : product.hasImage,
                    imageUrl : product.imageUrl,
                    price    : product.price,
                    total    : product.price * quantity,
                    quantity,
                });
                totalPrice += product.price * quantity;
            }
        }

        const details = result.map(({ name }) => name).join(", ");
        return [ result, details, totalPrice ];
    }, [ JSON.stringify(cartQuantities) ]);



    // Do the Render
    const hasProducts = list.length > 0;

    if (!open) {
        return <React.Fragment />;
    }
    return <Container
        title={getText("orderTitle")}
        onClose={handleClose}
    >
        <Content>
            {hasProducts ? list.map((elem) => <Product key={elem.id}>
                {elem.hasImage && <Image
                    src={elem.imageUrl}
                    alt={elem.name}
                />}
                <Name>{elem.name}</Name>
                <Total>{Utils.formatPrice(elem.total)}</Total>
                <Buttons>
                    <Icon icon="minus" onClick={() => handleRemove(elem)} />
                    <div>{elem.quantity}</div>
                    <Icon icon="plus" onClick={() => handleAdd(elem)} />
                </Buttons>
            </Product>) : <Empty>{getText("orderEmpty")}</Empty>}
        </Content>

        <Footer>
            <Button
                isHidden={!hasProducts}
                onClick={handleConfirm}
                message={getText("confirmCart")}
                isPrimary
            />
            <Button
                onClick={handleCancel}
                message={getText("cancelCart")}
            />
        </Footer>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Cart.propTypes = {
    open : PropTypes.bool.isRequired,
};

export default Cart;
