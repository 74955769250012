import React                from "react";
import PropTypes            from "prop-types";
import API                  from "./API";



// Variables
const initialState = {
    error          : false,
    loading        : true,
    cartHash       : "",
    backUrl        : "",

    currentSpace   : { id : 0 },
    banners        : [],
    spaces         : [],

    currentProduct : { id : 0 },
    products       : [],
    prodPerPage    : 0,
    totalProducts  : 0,
    spaceTotals    : {},

    showSearch     : false,
    currentSearch  : "",
    results        : [],
    totalResults   : 0,

    showCart       : false,
    cartProducts   : {},
    cartQuantities : {},
};
const rootReducer = (state, action) => {
    return { ...state, ...action };
};
const Context = React.createContext([]);



// Texts
const texts = {
    searchPlaceholder  : "Buscar medicamento ...",
    searchResultsTitle : "Resultados de búsqueda",
    searchNoneResults  : "No hay resultados para la búsqueda",

    showAll            : "Ver todo",
    laboratory         : "Laboratorio",

    orderTitle         : "Mi pedido",
    orderEmpty         : "Agrega medicamentos para verlos aquí ...",
    confirmCart        : "Confirmar pedido",
    cancelCart         : "Cancelar pedido",
};



/**
 * Creates the Store Provider
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Provider({ children }) {
    const [ state, dispatch ] = React.useReducer(rootReducer, initialState);
    const store = React.useMemo(() => [ state, dispatch ], [ state ]);

    return <Context.Provider value={store}>
        {children}
    </Context.Provider>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Provider.propTypes = {
    children : PropTypes.any,
};



/**
 * Returns the Store Hook
 * @returns {Array}
 */
function useStore() {
    return React.useContext(Context);
}

/**
 * Returns the State Hook
 * @returns {Object}
 */
function useState() {
    const [ state ] = useStore();
    return state;
}

/**
 * Returns the Dispatch Hook
 * @returns {Function}
 */
function useDispatch() {
    const [ , dispatch ] = useStore();
    return dispatch;
}



/**
 * Returns the Show Page Hook
 * @returns {Function}
 */
function useShowPage() {
    const { showSearch } = useState();
    const dispatch = useDispatch();

    return (currentSpace = { id : 0 }, currentSearch = "") => {
        dispatch({
            currentSpace,
            currentSearch,
            showSearch : !currentSearch ? false : showSearch,
        });
    };
}

/**
 * Returns the Show Product Hook
 * @returns {Function}
 */
function useShowProduct() {
    const dispatch = useDispatch();

    return (currentProduct = { id : 0 }) => {
        dispatch({ currentProduct });
    };
}

/**
 * Returns the Add Cart Hook
 * @returns {Function}
 */
function useShowCart() {
    const dispatch = useDispatch();

    return (showCart = false) => {
        dispatch({ showCart });
    };
}

/**
 * Returns the Add Cart Hook
 * @param {Object} product
 * @returns {Array}
 */
function useAddCart(product) {
    const { cartHash, cartQuantities, cartProducts } = useState();
    const dispatch  = useDispatch();
    const productID = product.id;

    const update = (increase) => {
        const quantity = Math.max((cartQuantities[productID] || 0) + increase, 0);

        cartQuantities[productID] = quantity;
        cartProducts[productID]   = product;

        if (cartHash) {
            API.setProduct({ cartHash, productID, quantity });
        }
        dispatch({ cartQuantities, cartProducts });
    };
    return [ cartQuantities[productID] || 0, update ];
}

/**
 * Returns the Set Cart Hook
 * @returns {Function}
 */
function useSetCart() {
    const { cartHash, cartQuantities } = useState();
    const dispatch = useDispatch();

    return (productID, quantity) => {
        cartQuantities[productID] = Math.max(quantity, 0);

        if (cartHash) {
            API.setProduct({ cartHash, productID, quantity });
        }
        dispatch({ cartQuantities });
    };
}



/**
 * Returns the Text Hook
 * @returns {Object}
 */
function useText() {
    return (property, defaultValue = "") => {
        return texts[property] || defaultValue;
    };
}

/**
 * Returns the Get Space Hook
 * @returns {Function}
 */
function useGetSpace() {
    const { spaces } = useState();

    return (spaceID) => {
        return spaces.find(({ id }) => id === Number(spaceID)) || {};
    };
}



/**
 * Returns a function to Initialize the Store
 * @returns {Function}
 */
function useInitial() {
    const dispatch = useDispatch();

    return async () => {
        // Obtain the store hash
        const cartHash = window.location.pathname.substring(1);

        // Obtain the initial data
        const result = await API.getInitial({ cartHash });

        if (result.error) {
            dispatch({
                error   : true,
                loading : true,
            });
            return;
        }

        // Calculate the Totals per Space
        const spaceTotals = {};
        for (const space of result.spaces) {
            spaceTotals[space.id] = result.products.filter(({ spaceIDs }) => spaceIDs.includes(String(space.id))).length;
        }

        // Store the data
        dispatch({
            error         : false,
            loading       : false,

            cartHash       : cartHash === "preview" ? "" : cartHash,
            backUrl        : result.backUrl,
            banners        : result.banners,
            spaces         : result.spaces,
            products       : result.products,
            prodPerPage    : result.prodPerPage,
            totalProducts  : result.products.length,
            spaceTotals    : spaceTotals,
            cartQuantities : result.cartQuantities || {},
            cartProducts   : result.cartProducts   || {},
        });
    };
}

/**
 * Returns a function to Fetch Products
 * @returns {Function}
 */
function useFetchProducts() {
    const { currentSpace, products, prodPerPage, totalProducts, spaceTotals } = useState();
    const dispatch = useDispatch();

    return async () => {
        const from = spaceTotals[currentSpace.id] || 0;
        if (!from || from % prodPerPage !== 0) {
            return;
        }

        const result = await API.getProducts({
            spaceID : currentSpace.id,
            from    : spaceTotals[currentSpace.id],
        });

        // Store the data
        dispatch({
            products      : [ ...products, ...result.products ],
            totalProducts : totalProducts + result.products.length,
            spaceTotals   : {
                ...spaceTotals,
                [currentSpace.id] : from + result.products.length,
            },
        });
    };
}

/**
 * Returns a function to Fetch Results
 * @returns {Function}
 */
function useFetchResults() {
    const { currentSpace, currentSearch, results, totalResults, prodPerPage } = useState();
    const dispatch = useDispatch();

    return async (search) => {
        const newSearch = search !== currentSearch;
        if (!newSearch && totalResults % prodPerPage !== 0) {
            return;
        }

        const from   = newSearch ? 0 : totalResults;
        const result = await API.getResults({
            search, from,
            spaceID : currentSpace.id,
        });

        // Store the data
        dispatch({
            showSearch   : true,
            results      : newSearch ? result.results : [ ...results, ...result.results ],
            totalResults : from + result.results.length,
        });
    };
}

/**
 * Returns a function to Confirm the Cart
 * @returns {Function}
 */
function useConfirmCart() {
    const { cartHash } = useState();

    return async () => {
        if (!cartHash) {
            return false;
        }

        const result = await API.confirmCart({ cartHash });
        return !result.error;
    };
}

/**
 * Returns a function to Cancel the Cart
 * @returns {Function}
 */
function useCancelCart() {
    const { cartHash } = useState();

    return async () => {
        if (!cartHash) {
            return false;
        }

        const result = await API.cancelCart({ cartHash });
        return !result.error;
    };
}




// The public API
export default {
    Provider,
    useState,

    useShowPage,
    useShowProduct,
    useShowCart,
    useAddCart,
    useSetCart,

    useText,
    useGetSpace,

    useInitial,
    useFetchProducts,
    useFetchResults,
    useConfirmCart,
    useCancelCart,
};
