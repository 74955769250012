/* spell-checker: ignore  Busqueda, Categoria, Categ */


/**
 * @private
 * Sends an event to Google Analytics
 * @param {String} func
 * @param {String} name
 * @param {Object} params
 * @returns {Void}
 */
function _sendEvent(func, name, params) {
    window.gtag("event", name, params);

    // Log the event in the console for debugging
    const url = window.location.href;
    if (url.includes("localhost") || url.includes("benvida.local") || url.includes("benvida.ar")) {
        console.log({ func, name, params });
    }
}

/**
 * @private
 * Calculates the scroll percentage
 * @param {Number} currentScroll
 * @param {Number} totalScroll
 * @returns {Number}
 */
function _getScrollPercent(currentScroll, totalScroll) {
    return Math.round((currentScroll / totalScroll) * 100);
}

/**
 * @private
 * Calculates the seconds open
 * @param {Number} openTime
 * @returns {Number}
 */
function _getOpenSeconds(openTime) {
    return Math.round((Date.now() - openTime) / 1000);
}



/**
 * Sends a search event to Google Analytics (1)
 * @param {String} searchValue
 * @returns {Void}
 */
function search(searchValue) {
    _sendEvent("search", "Busqueda", {
        Busqueda : searchValue,
    });
}

/**
 * Goes to a Space from the Spaces slider (2)
 * @param {String} spaceName
 * @returns {Void}
 */
function gotoSpaceFromSpaces(spaceName) {
    _sendEvent("gotoSpaceFromSpaces", "Menu_Categoria", {
        Click_Categoria : spaceName,
    });
}

/**
 * Scrolls in a Space (3)
 * @param {Number} currentScroll
 * @returns {Void}
 */
function scrollInSpace(currentScroll) {
    const totalScroll = document.body.offsetHeight - window.innerHeight;

    _sendEvent("scrollInSpace", "Menu_Categoria", {
        Scroll_Categoria : _getScrollPercent(currentScroll, totalScroll),
    });
}



/**
 * Add a Product to the Cart (3a/3b/3c/3d)
 * @param {Object} product
 * @returns {Void}
 */
function addProductToCart(product) {
    _sendEvent("addProductToCart", "Item_PagCat", {
        ClickMas_ItemCat : 1,
        Id_Categoria     : product.categoryID,
        Click_ItemCat    : product.name,
        Valor_ItemCat    : product.price,
    });
}

/**
 * Open the Product Dialog (4a/4b/4c y 7a/7b/7c)
 * @param {Object} product
 * @returns {Void}
 */
function openProductDialog(product) {
    _sendEvent("openProductDialog", "Modal_Item", {
        OpenModal  : product.name,
        CategModal : product.categoryName,
        ValorModal : product.price,
    });
}


/**
 * Close the Product Dialog (4d y 7d)
 * @param {Object} product
 * @param {Number} openTime
 * @returns {Void}
 */
function closeProductDialog(product, openTime) {
    _sendEvent("closeProductDialog", "Modal_Item", {
        CerrarModal : 1,
        OpenModal   : product.name,
        CategModal  : product.categoryName,
        ValorModal  : product.price,
        TimeModal   : _getOpenSeconds(openTime),
    });
}

/**
 * Add a Product to the Dialog (4e y 7e)
 * @param {Object} product
 * @param {Number} openTime
 * @returns {Void}
 */
function addInProductDialog(product, openTime) {
    _sendEvent("addInProductDialog", "Modal_Item", {
        CMasModal  : 1,
        OpenModal  : product.name,
        CategModal : product.categoryName,
        ValorModal : product.price,
        TimeModal  : _getOpenSeconds(openTime),
    });
}

/**
 * Remove a Product from the Dialog (4f y 7f)
 * @param {Object} product
 * @param {Number} openTime
 * @returns {Void}
 */
function removeInProductDialog(product, openTime) {
    _sendEvent("removeInProductDialog", "Modal_Item", {
        CMenosModal : -1,
        OpenModal   : product.name,
        CategModal  : product.categoryName,
        ValorModal  : product.price,
        TimeModal   : _getOpenSeconds(openTime),
    });
}



/**
 * Scrolls in a Home Space (6a)
 * @param {Number} currentScroll
 * @param {Number} totalScroll
 * @returns {Void}
 */
function scrollInHomeSpace(currentScroll, totalScroll) {
    _sendEvent("scrollInHomeSpace", "SliderCat_home", {
        Scroll_Slider : _getScrollPercent(currentScroll, totalScroll),
    });
}

/**
 * Goes to a Space from the "View all" in the Home (6b)
 * @param {String} spaceName
 * @returns {Void}
 */
function gotoSpaceFromViewAll(spaceName) {
    _sendEvent("gotoSpaceFromViewAll", "SliderCat_home", {
        Click_VerTodo_Slider : spaceName,
    });
}

/**
 * Goes to a Space from the Title in the Home (6c)
 * @param {String} spaceName
 * @returns {Void}
 */
function gotoSpaceFromTitle(spaceName) {
    _sendEvent("gotoSpaceFromTitle", "SliderCat_home", {
        Click_Titulo_Slider : spaceName,
    });
}



/**
 * Opens the Cart (8)
 * @returns {Void}
 */
function openCart() {
    _sendEvent("openCart", "Modal_Carrito", {
        Open_Carrito : 1,
    });
}

/**
 * Opens the Cart with Items (9)
 * @param {Number} totalItems
 * @param {String} details
 * @param {Number} totalPrice
 * @returns {Void}
 */
function openCartWithItems(totalItems, details, totalPrice) {
    _sendEvent("openCartWithItems", "Modal_Carrito", {
        Open_Carrito    : 1,
        Items_Carrito   : totalItems,
        Detalle_Carrito : details,
        Valor_Carrito   : totalPrice,
    });
}

/**
 * Cancels the Cart (10)
 * @param {Number} openTime
 * @returns {Void}
 */
function cancelCart(openTime) {
    _sendEvent("closeCart", "Modal_Carrito", {
        Cancelar_Carrito : 1,
        Time_Carrito     : _getOpenSeconds(openTime),
    });
}

/**
 * Closes the Cart (11)
 * @param {Number} openTime
 * @returns {Void}
 */
function closeCart(openTime) {
    _sendEvent("closeCart", "Modal_Carrito", {
        Cerrar_Carrito : 1,
        Time_Carrito   : _getOpenSeconds(openTime),
    });
}

/**
 * Removes a Product from the Cart (12)
 * @param {String} productName
 * @returns {Void}
 */
function removeProductFromCart(productName) {
    _sendEvent("removeProductFromCart", "Modal_Carrito", {
        Edit_Items_Carrito : productName,
    });
}

/**
 * Edits a Product in the Cart (13)
 * @param {String} productName
 * @param {Number} quantity
 * @returns {Void}
 */
function editProductInCart(productName, quantity) {
    _sendEvent("editProductInCart", "Modal_Carrito", {
        Detalle_Carrito        : productName,
        EditCant_Items_Carrito : quantity,
    });
}

/**
 * Confirms the Cart (14)
 * @param {Number} totalItems
 * @param {String} details
 * @param {Number} totalPrice
 * @returns {Void}
 */
function confirmCart(totalItems, details, totalPrice) {
    _sendEvent("confirmCart", "Modal_Carrito", {
        Confirmar_Carrito : 1,
        Items_Carrito     : totalItems,
        Detalle_Carrito   : details,
        Valor_Carrito     : totalPrice,
    });
}



/**
 * Goes to a Banner (15)
 * @param {String} value
 * @returns {Void}
 */
function gotoBanner(value) {
    _sendEvent("gotoBanner", "Home_Banner", {
        Click_Banner : value,
    });
}



// The public API
export default {
    search,
    gotoSpaceFromSpaces,
    scrollInSpace,

    addProductToCart,
    openProductDialog,
    addInProductDialog,
    removeInProductDialog,
    closeProductDialog,

    scrollInHomeSpace,
    gotoSpaceFromViewAll,
    gotoSpaceFromTitle,

    openCart,
    openCartWithItems,
    cancelCart,
    closeCart,
    removeProductFromCart,
    editProductInCart,
    confirmCart,

    gotoBanner,
};
