import React                from "react";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Icon                 from "Components/Utils/Icon";
import Html                 from "Components/Utils/Html";
import Button               from "Components/Utils/Button";

// Styled
import Styled, {
    keyframes,
} from "styled-components";



// Animations
const animation = keyframes`
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(0.9, 0.9);
    }
`;

// Styles
const Container = Styled.footer.attrs(({ showCart }) => ({ showCart }))`
    position: fixed;
    bottom: 0;
    right: calc((100vw - 800px) / 2);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 0 0 24px 0;
    z-index: 20;

    ${(props) => props.showCart && "pointer-events: none;"}

    @media (max-width: 800px) {
        right: var(--main-padding);
    }
`;

const Buy = Styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 12px;
    color: var(--store-buy-text);
    font-size: 16px;
    border: none;
    border-radius: var(--border-radius-small);
    background-color: var(--store-buy-bg);
    box-shadow: var(--box-shadow);
    cursor: pointer;

    .icon {
        font-size: 18px;
        margin-bottom: -2px;
    }

    &:focus {
        animation: ${animation} 0.5s ease-in-out backwards;
    }
    &:active {
        animation: none;
    }
`;

const Quantity = Styled.div`
    position: absolute;
    height: 20px;
    right: -2px;
    top: -10px;
    width: 20px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    background: red;
    color: white;
    border-radius: 100px;
`;



/**
 * The Floater
 * @returns {React.ReactElement}
 */
function Floater() {
    const { currentProduct, showCart, cartQuantities, cartProducts } = Store.useState();

    const showCartToggle = Store.useShowCart();


    // Handles the Open Cart
    const handleOpenCart = () => {
        showCartToggle(true);
    };

    // Handles the Scroll to Top
    const handleScroll = () => {
        window.scrollTo({ top : 0, behavior : "smooth" });
    };


    // Calculates the total
    const [ totalQuantity, totalPrice ] = React.useMemo(() => {
        let totalQuantity = 0;
        let totalPrice    = 0;

        for (const [ productID, quantity ] of Object.entries(cartQuantities)) {
            if (quantity > 0) {
                totalQuantity += quantity;
                totalPrice    += cartProducts[productID].price * quantity;
            }
        }
        return [ totalQuantity, totalPrice ];
    }, [ JSON.stringify(cartQuantities) ]);


    // Variables
    const showTop = Boolean(!currentProduct.id && !showCart);


    // Do the Render
    return <Container showCart={showCart}>
        <Button
            isHidden={!showTop}
            icon="up"
            onClick={handleScroll}
            isPrimary
        />

        <Buy onClick={() => handleOpenCart()}>
            <Icon icon="product" />
            <Html>{Utils.formatPrice(totalPrice)}</Html>
            <Quantity>{totalQuantity}</Quantity>
        </Buy>
    </Container>;
}

export default Floater;
